.abc {
  background-color: #fafafa !important;
}
.box {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px 20px;
  margin-bottom: 20px;
}
.main__headings {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: -0.01em;
  color: #7e57c2;
}
.paragraphs {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6c7686;
}
.sub__headings {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #7e57c2;
}

.table__header {
  background-color: #7e57c2 !important;
  border: none !important;
  border: 1px solid #dadada !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
}
.table__cell {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  border: none !important;
  border: 1px solid #dadada !important;
  color: #6c7686 !important;
}

.required__chip {
  background: #66bb6a !important;
  border-radius: 2px !important;
  color: white !important;
  height: 14px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  padding: 8px !important;
}
.optional__chip {
  background: #0091d1 !important;
  border-radius: 2px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  padding: 8px !important;
  color: #ffffff !important;
    height: 14px !important;

}
.type__chip {
  background: rgba(126, 87, 194, 0.4) !important;
  border-radius: 12px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  color: #7e57c2 !important;
}

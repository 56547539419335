/* importing all fonts here   */

@font-face {
    src: url(./fonts/PlusJakartaDisplay-Bold.otf);
    src: url(./fonts/PlusJakartaDisplay-BoldItalic.otf);
    src: url(./fonts/PlusJakartaDisplay-Italic.otf);
    src: url(./fonts/PlusJakartaDisplay-Light.otf);
    src: url(./fonts/PlusJakartaDisplay-LightItalic.otf);
    src: url(./fonts/PlusJakartaDisplay-Medium.otf);
    src: url(./fonts/PlusJakartaDisplay-MediumItalic.otf);
    src: url(./fonts/PlusJakartaDisplay-Regular.otf);
    src: url(./fonts/PlusJakartaText-Bold.otf);
    src: url(./fonts/PlusJakartaText-BoldItalic.otf);
    src: url(./fonts/PlusJakartaText-Italic.otf);
    src: url(./fonts/PlusJakartaText-Light.otf);
    src: url(./fonts/PlusJakartaText-LightItalic.otf);
    src: url(./fonts/PlusJakartaText-Regular.otf);
    font-family: "Plus Jakarta Sans";
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@font-face {
    src: url(fonts/PlusJakartaDisplay-Bold.otf);
    font-family: "Plus Jakarta Sans";
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MTableToolbar-root-5 {
    padding-right: 8px;
}

*:focus {
    outline: none !important;
    border: none !important;
}


/*
*:hover {
    outline: none !important;
    border: none !important;
} */

.swal-button--confirm {
    background-color: #6260ef !important;
}
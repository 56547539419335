/* sidebar active not active state color  */

@import "/src/Styles/Variables.scss";
.active {
    color: $primary-color !important;
    /* color: rgba(98, 96, 239, 1) !important; */
    text-decoration: none !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 18px;
}

.activeItem {
    border-radius: 8px !important;
    background-color: #efefff !important;
    border-left: 7px solid $primary-color !important;
}

.notactiveItem {
    border-radius: 8px !important;
    border-left: 7px solid rgb(255, 255, 255) !important;
}

.notActive {
    color: rgba(112, 112, 124, 1) !important;
    text-decoration: none !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.noData-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noData-container p {
    text-align: center;
    font-size: 26px;
}

.noData-svg {
    max-width: 200px;
    max-height: 300px;
}

.sell-stats-heading {
    display: flex;
    align-items: baseline;
    font-weight: 600;
}


/* monthly view cards customized media queries when sidebar is false*/

@media only screen and (max-width: 350px) {
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 350px) {
    .calender_card {
        width: 30% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 400px) {
    .calender_card {
        width: 37% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 450px) {
    .calender_card {
        width: 41% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 500px) {
    .calender_card {
        width: 47% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 550px) {
    .calender_card {
        width: 51% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 600px) {
    .calender_card {
        width: 56% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 650px) {
    .calender_card {
        width: 61% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 700px) {
    .calender_card {
        width: 66% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 750px) {
    .calender_card {
        width: 71% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 800px) {
    .calender_card {
        width: 76% !important;
    }
    .cardsForm {
        width: 250px !important;
    }
}

@media only screen and (min-width: 850px) {
    .calender_card {
        width: 80% !important;
    }
    .cardsForm {
        width: 100% !important;
    }
}

@media only screen and (min-width: 900px) {
    .calender_card {
        width: 85% !important;
    }
}

@media only screen and (min-width: 960px) {
    .calender_card {
        width: 90% !important;
    }
}

@media only screen and (min-width: 1000px) {
    .calender_card {
        width: 97% !important;
    }
}

@media only screen and (min-width: 1049px) {
    .calender_card {
        width: 99% !important;
    }
}

@media only screen and (min-width: 1100px) {
    .calender_card {
        width: 100% !important;
    }
}


/* monthly view cards customized media queries when sidebar is true*/

@media only screen and (min-width: 500px) {
    .calender_card_sidebar {
        width: 40% !important;
    }
}

@media only screen and (min-width: 800px) {
    .calender_card_sidebar {
        width: 50% !important;
    }
}

@media only screen and (min-width: 850px) {
    .calender_card_sidebar {
        width: 55% !important;
    }
}

@media only screen and (min-width: 900px) {
    .calender_card_sidebar {
        width: 60% !important;
    }
}

@media only screen and (min-width: 950px) {
    .calender_card_sidebar {
        width: 65% !important;
    }
}

@media only screen and (min-width: 1000px) {
    .calender_card_sidebar {
        width: 70% !important;
    }
}

@media only screen and (min-width: 1050px) {
    .calender_card_sidebar {
        width: 75% !important;
    }
}

@media only screen and (min-width: 1100px) {
    .calender_card_sidebar {
        width: 80% !important;
    }
}

@media only screen and (min-width: 1150px) {
    .calender_card_sidebar {
        width: 85% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .calender_card_sidebar {
        width: 90% !important;
    }
}

@media only screen and (min-width: 1250px) {
    .calender_card_sidebar {
        width: 95% !important;
    }
}

@media only screen and (min-width: 1300px) {
    .calender_card_sidebar {
        width: 100% !important;
    }
}


/* sell cards  */

.sell_cards {
    width: 90% !important;
}


/* line chart */

.line_chart {
    margin-top: 5% !important;
}

.line_chart_sidebar {
    margin-top: 5% !important;
}


/* line chart without sidebar */

@media only screen and (min-width: 450px) {
    .line_chart {
        width: 0% !important;
    }
}

@media only screen and (min-width: 650px) {
    .line_chart {
        width: 50% !important;
    }
}

@media only screen and (min-width: 730px) {
    .line_chart {
        width: 60% !important;
    }
}

@media only screen and (min-width: 850px) {
    .line_chart {
        width: 70% !important;
    }
}

@media only screen and (min-width: 1000px) {
    .line_chart {
        width: 90% !important;
    }
}

@media only screen and (min-width: 1250px) {
    .line_chart {
        width: 90% !important;
    }
}


/* line chart with sidebar true*/

@media only screen and (min-width: 350px) {
    .line_chart_sidebar {
        width: 30% !important;
    }
}

@media only screen and (min-width: 740px) {
    .line_chart_sidebar {
        width: 40% !important;
    }
}

@media only screen and (min-width: 850px) {
    .line_chart_sidebar {
        width: 50% !important;
    }
}

@media only screen and (min-width: 977px) {
    .line_chart_sidebar {
        width: 60% !important;
    }
}

@media only screen and (min-width: 1080px) {
    .line_chart_sidebar {
        width: 70% !important;
    }
}

@media only screen and (min-width: 1230px) {
    .line_chart_sidebar {
        width: 80% !important;
    }
}

@media only screen and (min-width: 1350px) {
    .line_chart_sidebar {
        width: 90% !important;
    }
}


/*----------------------------------- Monthly view styling end ----------------------------------- */


/*----------------------------------- Daily view styling  ----------------------------------- */

.daily_view {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.calender_card_dailyview {
    max-width: 400px !important;
    padding: 30px;
}

.css-1mei6sw-MuiPaper-root-MuiAppBar-root {
    background-color: rgb(255, 255, 255) !important;
}

.header_appbar {
    background-color: rgb(255, 255, 255) !important;
}


/* daily stat card  */

.sell_stat_card_title {
    font-family: " Plus Jakarta Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 144px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
}

.i__icon {
    display: flex;
    align-items: center;
    padding-left: 6px;
    cursor: pointer;
}

.select_label {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 15px !important;
    color: $primary-color !important;
    /* color: #6260ef !important; */
}

.MuiSelect-outlined {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
}

.table_head {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    text-transform: uppercase;
    /* unselected */
    color: #70707c !important;
}

.pricing_cell {
    background: #ffffff;
    box-shadow: 1px 1px 1px 1px #cbcbcb;
    box-sizing: border-box;
    border-radius: 5px;
    pointer-events: none;
    padding: 7px;
    text-align: center;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    /* identical to box height */
    display: flex!important;
    justify-content: center !important;
    color: #000000;
    width: 70px;
}

.dynamicity_select {
    background: #ffffff;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 6px;
    text-align: center;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    /* identical to box height */
    width: 100%;
    color: #000000;
}

.dialogue_items_heading {
    font-weight: bold !important;
    font-size: 18px !important;
    margin-left: auto !important;
    color: $primary-color !important;
    /* color: rgba(98, 96, 239, 1) !important; */
}

.dialogue_items_heading_child {
    font-weight: bold !important;
    font-size: 14px !important;
    margin-left: auto !important;
    color: #000;
}

.propertyList {
    cursor: pointer;
}

.dialogue_divider {
    width: 100%;
    margin-bottom: 10px !important;
}


/* daily stat cards design */

.sell_cards_progress_bar {
    width: 90px !important;
    height: 90px;
    margin-left: auto;
    margin-right: 40px;
    /* position: absolute; */
    /* left: 0; */
    /* top: 0;
  right: 0; */
    /* margin-top: 100px; */
    margin-bottom: 30px;
}

.sell_cards_progress_bar_on_opensidebar {
    width: 100px !important;
    height: 100px;
    margin-left: auto;
    margin-right: 40px;
    /* position: absolute;
    top: 0;
    right: 0; */
    /* margin-top: 40px; */
}

@media only screen and (min-width: 1680px) {
    .sell_cards_progress_bar_on_opensidebar {
        width: 100px !important;
        height: 100px;
        margin-left: auto;
        margin-right: 40px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 55px;
    }
    .sell_cards_progress_bar {
        width: 90px !important;
        height: 90px;
        margin-left: auto;
        margin-right: 40px;
        /* position: absolute; */
        /* left: 0; */
        /* top: 0;
    right: 0; */
        /* margin-top: 100px; */
        margin-bottom: 30px;
    }
    #heading2 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 18px;
        color: #000000;
        margin: 18px 5px 6px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (min-width: 1280px) {
    .sell_cards_progress_bar_on_opensidebar {
        width: 100px !important;
        height: 100px;
        margin-left: auto;
        margin-right: 40px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 55px;
    }
    .sell_cards_progress_bar {
        width: 90px !important;
        height: 90px;
        margin-left: auto;
        margin-right: 40px;
        /* position: absolute; */
        /* left: 0; */
        /* top: 0;
    right: 0; */
        /* margin-top: 100px; */
        margin-bottom: 30px;
    }
    #heading2 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        margin: 18px 5px 6px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 450px) {
    .sell_cards_progress_bar {
        width: 100px !important;
        height: 100px;
        margin-left: auto;
        margin-right: 40px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 55px;
    }
    .sell_cards_progress_bar {
        width: 90px !important;
        height: 90px;
        position: absolute;
        margin: 120px 200px 30px 0px;
    }
    .sell-stat-card-date {
        margin-top: 20px;
        float: right;
        position: absolute;
        right: 10px;
    }
    #heading2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        margin: 18px 5px 6px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


/* dev tools */

.token_copy {
    padding: 0px 20px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.devtools-card-title {
    padding: 20px 0 10px 20px;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-size: 18px !important;
    line-height: 18px;
}

.allowRmsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        margin: 0;
        font-size: 22px;
    }
    .MuiSwitch-switchBase.Mui-checked {
        color: $primary-color;
    }
    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background-color: $primary-color;
    }
}


/* Highlight the base room type */

.selected__pricing__btns {
    background-color: $primary-color !important;
    color: white !important;
    font-weight: bold !important;
    border-radius: 15px !important;
    margin: 3% 0 3% 0 !important;
}

.unselected__pricing__btns {
    border-radius: 15px !important;
    margin: 3% 0 3% 0 !important;
}

.pricing_table_alignments {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    width: 80% !important;
}

.pricing__section__width {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-left: auto;
    margin-right: auto
}

.edit__importance__block {
    display: flex;
}

.importance__edit__icon {
    margin: 7px 0 0 6px;
    pointer-events: none;
}

.importance__edit__icon_not_disable {
    margin: 7px 0 0 6px;
}


/* dashboard side bar */

.List {
    margin-top: 20px !important
}

.MuiSwitch-colorPrimary .Mui-checked {
    color: $primary-color !important;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
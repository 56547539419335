.progress-container {
    margin-top: 30px;
    padding: 10px 20%;
    gap: 10%;
    display: flex;
    flex-wrap: wrap;
}

.main-svg {
    max-width: 450px;
    max-height: 350px;
}

.process-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #a4a4a4;
    border-radius: 8px;
    width: 45%;
    margin-top: 20px;
}

.process-container p {
    margin: 0;
}

.process-container .check-icon {
    width: 30px;
}

.rotate-image {
    animation: rotation 2s linear infinite;
}
.comp__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.yd__pace_spacing {
    margin-bottom: 20px;
}

/* guage chart */


.sc-gauge {
    width: 200px;
    height: 200px;
margin: auto;
}

.sc-background {
    position: relative;
    height: 100px;
    margin-bottom: 10px;
    background-color: rgb(243, 42, 42);
    border-radius: 150px 150px 0 0;
    overflow: hidden;
    text-align: center;
}

.sc-mask {
    position: absolute;
    top: 20px;
    right: 20px;
    left: 20px;
    height: 80px;
    background-color: #fff;
    border-radius: 150px 150px 0 0
}

.sc-percentage {
    position: absolute;
    top: 100px;
    left: -200%;
    width: 400%;
    height: 400%;
    margin-left: 100px;
    background-color: #00aeef;
}

.sc-percentage {
    transform: rotate(158deg);
    transform-origin: top center;
    
}

.sc-min {
    float: left;
}

.sc-max {
    float: right;
    
}

.sc-value {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 48px;
    font-weight: 700
}


/* loader */

.setting_title_typo {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: bold;
    line-height: 30px;
  
    color: #000000;
  }
.documentation_page {
	display: flex;

	background: #f2f2f2;

	.left_sidebar {
		width: 250px;
		height: 100vh;
		background-color: #ffffff;

		.sidebar {
			padding: 10px;

			.sidebar_logo {
				display: flex;
				justify-content: center;
				padding: 25px 0;

				img {
					width: 138px;
					height: 44.59px;
					object-fit: contain;
				}
			}

			.close_icon {
				margin-left: 10px;
				margin-top: 10px;
				width: 32px;
				height: 32px;
				background: #7e57c2;
				border-radius: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				cursor: pointer;
			}

			.input_container {
				position: relative;
				margin: 30px 0;
				height: 48px;
				width: 100%;

				input {
					width: 100%;
					height: 100%;
					border: 0.8px solid #344054;
					border-radius: 8px;
					font-family: 'Inter';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					color: #344054;
					padding-left: 20px;

					&:hover {
						border-radius: 8px;
						outline: 0.8px solid #344054 !important;
					}

					&:focus {
						border-radius: 8px;
						outline: 0.8px solid #344054 !important;
					}
				}

				i {
					position: absolute;
					right: 17px;
					top: 14px;
					font-size: 20px;
					color: #7e57c2;
				}
			}

			.links_container {
				.title {
					font-weight: 500;
					font-size: 15px;
					font-family: 'Inter';

					.link {
						color: #000;
					}
				}

				.active {
					.title {
						background: #7e57c2;
						border-radius: 4px;

						.link {
							color: #fff;
						}
					}
				}

				.child_links_container {
					margin-top: 15px;
					.child_links {
						span {
							border-radius: 2px;
						}

						.link {
							color: #000;
						}
					}
				}
			}
		}
	}
}

@import "/src/Styles/Variables.scss";
.MonthlyView {
    background-color: white !important;
}

.css-t752vm {
    /* background-color: #6260ef !important; */
    background-color: $primary-color !important;
}

#title {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 18px;
    color: #000000;
    margin: 19px 1px;
}

.css-191lty2 {
    min-height: 71px !important;
}

#heading {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin: 6px 6px 6px 16px;
}

.css-5xe99f-MuiLinearProgress-bar1 {
    background-color: $primary-color !important;
    /* background-color: #6260ef !important; */
}

.css-1ygcj2i-MuiTableCell-root {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #70707c !important;
}

.css-1bigob2 {
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #70707c !important;
}

.css-1azl6jz-MuiTableCell-root {
    font-family: "Plus Jakarta Sans" !important;
    text-align: left !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #70707c !important;
}

.css-177gid-MuiTableCell-root {
    border-bottom: 0px !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #000000;
    text-align: left !important;
}

.css-1i36psw {
    border-bottom: 0px !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #000000;
    text-align: left !important;
}

.css-1ex1afd-MuiTableCell-root {
    border-bottom: 0px !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #000000;
}

.css-q34dxg {
    border-bottom: 0px !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #000000;
}

::-webkit-scrollbar {
    width: 5px;
    height: 36px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 100px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: $primary-color;
    /* background: #6260ef; */
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
    /* background: #6260ef; */
}

#user {
    padding: 20px 50px 20px 10px !important;
    background: rgba(98, 96, 239, 0.2);
    border-radius: 8px;
    margin-left: 10px;
    margin-bottom: 30px !important;
    font-family: "Plus Jakarta Sans" !important;
    font-style: normal;
    font-weight: bold;
}

#calendar {
    border: 2px solid #f2f2fe;
    box-sizing: border-box;
    border-radius: 8px;
    width: 354px;
    height: 510px;
    margin: 40px;
    padding: 20px 40px;
}

.MuiTypography-noWrap {
    color: black;
}

.css-177ic5c {
    top: 188px !important;
}

.btn {
    width: 135px;
    height: 36px;
    /* background: #6260ef; */
    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 12px;
}

.btn:focus {
    color: pink;
    text-decoration: none;
}

.AI1 {
    max-width: 300px;
    width: 100%;
    height: 251px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    padding: 0px 28px 0px 35px;
}

.cardfocus:focus-within {
    border: 2px solid $primary-color;
    /* border: 2px solid #6260ef; */
}

.circle {
    max-width: 26px;
    width: 100%;
    min-height: 26px;
    border: 2px solid #d7d7d7;
    border-radius: 50%;
    margin: 33px 8px 0px 64px;
    cursor: pointer;
    color: #ffffff;
}

.circle:focus-within {
    background-color: $primary-color;
    /* background-color: #6260ef; */
}

.MTableToolbar-root-5 {
    display: none;
}

.MuiTableBody-root {
    padding-top: 0px;
    margin-top: 0px;
    font-weight: 600;
}

.MuiTableRow-root:nth-of-type(n + 2) {
    background-color: #f8f8f8
}

.MuiTableBody-root:nth-child(odd) {
    background-color: #f8f8f8 !important
}
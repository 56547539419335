@import "/src/Styles/Variables.scss";
@font-face {
    src: url(../../fonts/PlusJakartaDisplay-Medium.otf);
    font-family: "Plus Jakarta Sans";
}

body {
    font-family: "Plus Jakarta Sans";
    background-color: white;
    overflow-x: hidden;
    z-index: 20;
}

html {
    font-family: "Plus Jakarta Sans";
    background-color: white;
    overflow-x: hidden;
}

.Header {
    position: fixed;
    top: 0px;
    /* Stick it to the top */
    max-height: 190px;
    width: 100vw;
    height: 100px;
    display: grid;
    grid-template-areas: "logo nav";
    background-color: #ffffff;
    /* z-index: 20; */
}

.MonthlyLogo img {
    position: static !important;
    vertical-align: top !important;
    margin-left: 50px;
    border-style: none;
    /* padding-left: -10px; */
}

.Logo {
    grid-area: logo;
    width: 190px;
    height: 70px;
    left: 90.58px;
    top: 7px;
}

.Nav {}

.Nav a {
    color: rgba(126, 126, 126, 1);
    font-size: 15px;
    font-weight: 700;
    transition: 0.1s;
    text-decoration: none;
}

.Nav button {
    margin-top: 1px;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 11px;
    border: 3px solid $primary-color;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    background-color: #282c34;
    border-radius: 11px;
}

.Burger {
    position: fixed;
    top: 15px;
    left: 20px;
    /* Stick it to the top */
    display: none;
    grid-area: burger;
    margin: 0 0px 0 0;
    padding: 0;
    justify-self: end;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
    color: black;
    z-index: 21;
}

.Burger:active {
    transform: scale(1.1);
}

@media (max-width: 1200px) {
    .Header {
        grid-template-areas: "logo burger" "nav left";
    }
    .Burger {
        display: inline;
        align-content: flex-start;
    }
}

.NavAnimation {
    opacity: unset;
}

.NavAnimation-enter {
    opacity: 1;
    transform: scale(0.2);
}

.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 250ms, transform 250ms;
}

.NavAnimation-exit {
    opacity: 1;
}

.NavAnimation-exit-active {
    opacity: 1;
    transform: scale(0.2);
    transition: opacity 250ms, transform 250ms;
}

.ul {
    list-style: none;
}

.ul li::before {
    content: "\2022";
    color: $primary-color;
    font-weight: bold;
    font-size: large;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.content {
    background-color: white;
    padding: 0 8%
}

.A button {
    padding: 15px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: $primary-color;
    border-radius: 11px;
    width: 150px;
}

.grow {
    cursor: pointer;
    transition: 0ms;
}

.grow:hover {
    padding: 5px;
    transform: scale(1.1);
    box-shadow: grey;
    background-color: none;
    border-radius: 30px;
    cursor: pointer;
}

@keyframes myfirst {
    0% {
        right: 0px;
        top: 10px;
    }
    100% {
        right: 0px;
        top: 50px;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes zoom {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.09, 1.09);
    }
    100% {
        transform: scale(1, 1);
    }
}

.container1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 600px;
    background-color: white;
}

.container2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px;
    background-color: white;
}

.container3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 150px;
    background-color: white;
}

.Footer {
    position: static;
    bottom: 0px;
    /* Stick it to the bottom */
    right: 0px;
    left: 0px;
    width: auto;
    display: grid;
    background-color: "rgba(247, 248, 255, 1)";
    z-index: 20;
    flex-shrink: 0;
    justify-content: center;
}

label,
input {
    display: block;
    width: 100%;
}

label {
    margin-bottom: 5px;
    height: 22px;
}

input {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid black;
}

input.error {
    border-color: red;
}

.input-feedback {
    color: rgb(235, 54, 54);
    margin-top: -15px;
    font-size: 5px;
    margin-bottom: 20px;
}

.container1 h3,
h4 {
    font-size: 12px;
    padding-top: 18px;
    font-weight: bolder;
}

.signup_form_img {
    padding: 40% 0 !important;
}

.signin_form {
    margin-left: auto;
    margin-right: auto;
}

.Burger {
    padding-top: 25px;
}

.flex_nav {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 30px;
}

.nav_items {
    margin: 0 15px
}

.burger_icon {
    color: black !important;
    margin-top: 10px !important;
}

.sidebar_items {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: #7E7E7E;
}
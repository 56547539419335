@import '../../../../Styles/Variables.scss';
.proportions {
    .MuiSwitch-switchBase.Mui-checked {
        color: $primary-color;
    }
    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background-color: $primary-color;
    }
    .loader_main_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
    .proportions_main_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .save_button,
    .save_button:hover {
        height: 50px;
        margin-right: 2px;
        background-color: $primary-color;
        color: white;
        font-weight: bold;
    }
    .edit_button,
    .edit_button:hover {
        height: 50px;
        width: 70px;
        margin-right: 2px;
        background-color: #e0e0e0;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .setting_title_typo {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: bold;
        line-height: 30px;
        color: #000000;
    }
    .table_head {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        text-transform: uppercase;
        /* unselected */
        color: #70707c !important;
    }
    .base_room_type_symbol {
        background-color: $primary-color;
        color: white;
        padding: 15px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .no_base_room_type_symbol {
        padding: 15px;
        border-radius: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }
    /* proportion btn */
    .proportion_btn_one_active {
        background-color: $primary-color !important;
        width: 60px;
        border-radius: 10px 0 0 10px !important;
        color: #efefff !important;
    }
    .proportion_btn_one {
        width: 60px;
        border-radius: 10px 0 0 10px !important;
        color: #000000 !important;
    }
    .proportion_btn_two_active {
        background-color: $primary-color !important;
        width: 60px;
        border-radius: 0 10px 10px 0 !important;
    }
    .proportion_btn_two {
        width: 60px;
        border-radius: 0 10px 10px 0 !important;
        color: #000000 !important;
    }
    .toggle_switch {
        float: left;
    }
}